import cx from 'classnames';

import avatarPlaceholder from '../assets/avatar-placeholder.png';

type AvatarSize = 'xxs' | 'xs' | 'sm' | 'md' | 'lg';

interface AvatarProps extends React.ComponentPropsWithoutRef<'img'> {
  size?: AvatarSize;
  username: string;
}

export function Avatar({ className, username, src, size = 'md', ...rest }: AvatarProps) {
  return (
    <div className={cx('relative inline-flex overflow-hidden', className, { placeholder: !src })}>
      {src ? (
        <div
          className={cx(
            'bg-warmgray-900 text-warmgray-50 rounded-full block aspect-square overflow-hidden',
            classesBySize[size],
          )}
        >
          <img src={src} alt={`${username}'s avatar`} {...rest} loading="lazy" className="h-full w-full object-cover" />
        </div>
      ) : (
        <div
          className={cx(
            'bg-coldgray-50 text-secondary rounded-full block aspect-square overflow-hidden',
            classesBySize[size],
          )}
        >
          <span className={fontClassesBySize[size]} aria-hidden="true">
            <img
              src={avatarPlaceholder}
              alt={`${username}'s avatar`}
              {...rest}
              loading="lazy"
              className="h-full w-full object-cover"
            />
          </span>
        </div>
      )}
    </div>
  );
}

const classesBySize: Record<AvatarSize, string> = {
  xxs: 'w-5',
  xs: 'w-6',
  sm: 'w-12',
  md: 'w-20',
  lg: 'w-32',
};

const fontClassesBySize: Record<AvatarSize, string> = {
  xxs: 'text-xxs',
  xs: 'text-xs',
  sm: '',
  md: 'text-xl',
  lg: 'text-3xl',
};
